<script>
import Sidebar from '@/components/Sidebar'
import Income from '@/components/Income'
import 'v-charts/lib/style.css'
export default {
  name: 'dashboard',
  components: {
    Sidebar,
    Income
  },
  data() {
    return { t: null }
  },
  methods: {
    asd() {
      clearTimeout(this.t)
      this.t = setTimeout(this.IdleHandler, 30 * 60 * 1000)
    },
    IdleHandler() {
      this.$eventBus.$emit('logOutHandler', true)
      setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    },
    close() {
      // console.log('close')
      const menu = document.querySelector('.menu')
      const ham = document.querySelector('.ham')
      menu.style.left = '-320px'
      ham.style.display = 'block'
    }
  },
  created() {
    this.asd()
  },
  mounted() {
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
  },
  beforeDestroy() {
    clearTimeout(this.t)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss"></style>
