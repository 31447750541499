<script>
import { Avatar, Menu, Submenu, MenuItem, MenuItemGroup } from 'element-ui'
export default {
  name: 'sidebar',
  components: {
    'el-avatar': Avatar,
    'el-menu': Menu,
    'el-submenu': Submenu,
    'el-menu-item': MenuItem,
    'el-menu-item-group': MenuItemGroup
  },
  data() {
    return {
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      menuItems: [
        {
          name: 'ダッシュボード',
          type: 'el-icon-s-data',
          active: 'income',
          show: false
        },
        // {
        //   name: '送金明細',
        //   type: 'el-icon-tickets',
        //   active: 'detail',
        //   show: sessionStorage.getItem('session_id') !== '3294228999'
        // },
        {
          name: '売上集計',
          type: 'el-icon-s-order',
          active: 'list',
          show: false
        },
        {
          name: '出荷',
          type: 'el-icon-truck',
          active: 'shipping',
          show: false
        },
        {
          name: 'モール出荷集計',
          type: 'el-icon-truck',
          active: 'mallstoreshipping',
          show: false
        },
        {
          name: '返品・受取拒否',
          type: 'el-icon-s-goods',
          active: 'return',
          show: false
        },
        // { name: '在庫移動', type: 'el-icon-takeaway-box', active: 'Dispatch' },
        {
          name: '輸入一覧',
          type: 'el-icon-notebook-2',
          active: 'inputList',
          show: false
        },
        {
          name: 'セット商品',
          type: 'el-icon-copy-document',
          active: 'set',
          show: false
        },
        {
          name: '在庫移動',
          type: 'el-icon-receiving',
          active: 'Dispatch',
          show: false
        },
        {
          name: '月次在庫一覽',
          type: 'el-icon-shopping-cart-2',
          active: 'InventoryInfo',
          show: false
        },
        {
          name: '在庫総覧',
          type: 'el-icon-shopping-cart-2',
          active: 'InventoryDetail',
          show: false
        }
      ],
      supplier_name: sessionStorage.getItem('supplier_alias')
    }
  },
  computed: {
    img_src() {
      if (sessionStorage.getItem('supplier_img')) {
        return sessionStorage.getItem('supplier_img')
      }
      return '@/assets/website.png'
    }
  },
  methods: {
    openHandler() {},
    open() {
      const menu = document.querySelector('.menu')
      const ham = document.querySelector('.ham')
      menu.style.left = '0'
      ham.style.display = 'none'
    },
    close() {
      const menu = document.querySelector('.menu')
      const ham = document.querySelector('.ham')
      menu.style.left = '-320px'
      ham.style.display = 'block'
    }
  },
  mounted() {
    // console.log(sessionStorage.getItem('session_id') === '3294228999')
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
